import React from 'react';
import './PlayerTable.css';
const PlayerTable = ({ players }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Position</th>
          <th>Name</th>
          <th>Rank</th>
          <th>Division</th>
          <th>LP</th>
          <th>Wins</th>
          <th>Defeats</th>
          <th>Win Rate</th>
        </tr>
      </thead>
      <tbody>
        {players.map((player, index) => (
          <tr key={index}>
            <td>{index === 0 ? <img className='king' src='king.png' alt='King'/> : (index + 1)}</td>
            <td>{player.summonerName}</td>
            <td>{player.summonerRank}</td>
            <td>{player.summonerDivision}</td>
            <td>{player.summonerRankLP}</td>
            <td>{player.summonerRankWins}</td>
            <td>{player.summonerRankLosses}</td>
            <td className={player.summonerRankWinrateColor === 'green' ? 'winratiogreen' : 'winratiored'}>
              {player.summonerRankWinrate}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PlayerTable;